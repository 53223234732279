import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Paragraph wrap=flex",
        "componentName": "Paragraph",
        "wrap": "flex"
      }}>{`<Paragraph>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti. Quisque luctus vel ante quis vulputate. Nulla a iaculis
  tellus. Quisque quis neque eu nisi euismod.
</Paragraph>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Paragraph" mdxType="Props" />
    <h2 {...{
      "id": "color",
      "style": {
        "position": "relative"
      }
    }}>{`Color`}</h2>
    <p><inlineCode parentName="p">{`default: colorBlackBase`}</inlineCode></p>
    <p>{`You can change the paragraph's color by passing in a defined color name.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Paragraph color="primaryDark">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
<Paragraph color="green70">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
`}</code></pre>
    <h2 {...{
      "id": "decoration",
      "style": {
        "position": "relative"
      }
    }}>{`Decoration`}</h2>
    <p><inlineCode parentName="p">{`default: none`}</inlineCode></p>
    <p>{`Controls the text-decoration css attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Paragraph decoration="none">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
<Paragraph decoration="underline">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
<Paragraph decoration="line-through">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
`}</code></pre>
    <h2 {...{
      "id": "fontfamily",
      "style": {
        "position": "relative"
      }
    }}>{`FontFamily`}</h2>
    <p><inlineCode parentName="p">{`default: system`}</inlineCode></p>
    <p>{`Font-families can be used to discern between multiple uses of a Paragraph.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Paragraph fontFamily="primary">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
<Paragraph fontFamily="secondary">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
<Paragraph fontFamily="system">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
`}</code></pre>
    <h2 {...{
      "id": "fontweight",
      "style": {
        "position": "relative"
      }
    }}>{`FontWeight`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Controls the font-weight css attribute. Only 3 values are available. If a value
is missing, it will fallback on the more bold weight.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Paragraph fontWeight="inherit">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
<Paragraph fontWeight="regular">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
<Paragraph fontWeight="medium">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
<Paragraph fontWeight="bold">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
`}</code></pre>
    <h2 {...{
      "id": "textalign",
      "style": {
        "position": "relative"
      }
    }}>{`TextAlign`}</h2>
    <p><inlineCode parentName="p">{`default: inherit`}</inlineCode></p>
    <p>{`Controls the css text-align attribute.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Paragraph textAlign="inherit">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
<Paragraph textAlign="left">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
<Paragraph textAlign="right">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
<Paragraph textAlign="center">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
<Paragraph textAlign="justify">
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean varius euismod
  semper. Nulla lorem lorem, aliquam non neque in, dictum eleifend tortor.
  Suspendisse potenti.
</Paragraph>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      